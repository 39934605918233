<template>
	<RouterLink :to="{ name: 'leafwellPrimary' }">
		<div
			ref="mjCard"
			class="flex flex-wrap h-full bg-cover border border-gray-300 rounded-xl"
			:style="lazyLoadedBackgroundImage"
		>
			<div class="order-last w-full p-4 md:text-left lg:order-first lg:w-1/2">
				<h2 class="m-2 text-lg font-bold text-black">
					Get your {{ stateInfo.name }} medical marijuana card online!
				</h2>
				<div class="m-2 text-black align-middle text-md">
					Where's Weed has partnered with Leafwell, to make it easy to apply for a medical marijuana card in {{ stateInfo.name }}!
				</div>
				<div class="inline-block w-full px-4 py-2 font-bold text-center text-green-500 border-2 border-green-500 rounded md:mx-2 md:w-auto">
					Learn More
				</div>
			</div>
			<div
				:class="cardWrapperClass"
				class="flex items-center justify-center w-1/2 m-auto lg:flex-col"
			>
				<img
					class="p-4"
					height="88"
					width="256"
					src="@/assets/leafwell-logo.svg"
					alt="Leafwell Logo"
				>
				<img
					height="220"
					width="220"
					src="@/assets/partners/leafwell-hero.png"
					alt="Leafwell Doctor Icon"
					class="my-4 lg:mb-8 lg:mt-0"
				>
			</div>
		</div>
	</RouterLink>
</template>

<script async>
import { mapState } from 'vuex'

export default {
	props: {
		cardWrapperClass: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			imageSrc: require('@/assets/mj-card-background.png'),
			observed: false,
			observer: null
		}
	},
	computed: {
		...mapState({
			stateInfo: state => state.state || {}
		}),
		lazyLoadedBackgroundImage() {
			return this.observed ? `backgroundImage: url(${this.imageSrc})` : ''
		}
	},
	mounted() {
		if (window && 'IntersectionObserver' in window) {
			this.observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.observed = true
						this.observer.disconnect()
					}
				})
			})
			this.observer.observe(this.$refs.mjCard)
		} else {
			this.observed = true
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	}
}
</script>
