<template>
	<div class="bg-white">
		<HeroImage
			:title="stateInfo.name"
			:image="stateInfo.content && stateInfo.content.heroImage"
		/>
		<div class="max-w-6xl px-4 mx-auto lg:px-0">
			<h2 class="w-full py-4 text-3xl font-bold text-left">
				Cannabis in {{ stateInfo.name }}
			</h2>
			<div
				v-if="stateInfo.cities && stateInfo.cities.length > 0"
				class="flex justify-between w-full pb-3 space-x-3 overflow-x-scroll"
			>
				<div
					v-for="(city, index) in stateInfo.cities.slice(0, 6)"
					:key="index"
					class="min-w-36 md:min-w-0"
				>
					<LocationCard
						:card-type="CITY"
						:location-name="city.city"
						:location-url="city.url"
						:image-path="city.image"
					/>
				</div>
			</div>
			<div class="flex flex-col w-full gap-4 pb-4 my-4 md:flex-row">
				<div
					v-if="faqSnippet.length"
					class="w-full px-4 py-8 text-left border shadow-sm rounded-xl"
				>
					<h3 class="text-lg font-bold text-black">
						Common questions about cannabis in {{ stateInfo.name }}
					</h3>
					<ul class="py-3 mx-3 text-gray-300 list-disc">
						<li
							v-for="(faq, index) in faqSnippet"
							:key="index"
							class="mb-2"
						>
							<a
								:href="`#faq-${index}`"
								class="text-black hover:text-purple-500"
							>
								{{ faq.q }}
							</a>
						</li>
					</ul>
					<a
						href="#faq-section"
						class="border-2 border-green-500 py-2.5 px-4 rounded font-bold text-green-500"
					>
						View All
					</a>
				</div>

				<MjCard
					:card-wrapper-class="mjCardWrapperClass"
					class="w-full"
				/>
			</div>

			<div v-if="stateInfo.hasLaws">
				<h2 class="my-4 text-3xl font-bold text-left">
					{{ stateInfo.name }} Cannabis Laws
				</h2>

				<HTagNav
					v-if="stateContent"
					class="w-full text-left max-w-prose md:max-w-5xl"
					:markup="stateContent"
				/>
			</div>
			<div
				v-if="hasFaq"
				id="faq-section"
				class="my-4"
			>
				<WwFaq
					:faq="faqs"
					:heading="heading"
					:columns="2"
				/>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapState } from 'vuex'

import HeroImage from '@/components/multiUse/HeroImage.vue'
import WwFaq from '@/components/UI/faq/WwFaq.vue'
import { CITY } from '@/constants/state/locationCardTypes.js'
import MjCard from '@/views/city/components/MjCard.vue'
import LocationCard from '@/views/state/components/LocationCard.vue'

export default {
	components: {
		HeroImage,
		HTagNav: () => import('@/components/multiUse/HTagNav.vue'),
		MjCard,
		WwFaq,
		LocationCard
	},
	data() {
		return {
			CITY
		}
	},
	computed: {
		...mapState({
			stateInfo: state => state.state || {}
		}),
		hasFaq() { return !!this.faqSnippet?.length },
		hasRecentArticles () { return !!this.blogArticles?.length },
		faqSnippet() {
			return this.stateInfo?.content.faq.slice(0, 3)
		},
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		blogArticles() {
			return this.stateInfo.blogArticles.slice(0, 4)
		},
		faqs() {
			return this.stateInfo.content.faq
		},
		heading() {
			return 'Frequently asked questions about cannabis in ' + this.stateInfo.name
		},
		stateContent() {
			return this.stateInfo.content.main
		},
		mjCardWrapperClass() {
			return !this.faqSnippet.length ? 'xl:flex-row xl:px-20' : ''
		}
	}
}
</script>
