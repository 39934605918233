const destructureCountry = function (data) {
	const {
		id,
		name,
		short
	} = data.country

	const states = data.country.states.map((state) => {
		const {
			id,
			name,
			short,
			hero_image: heroImage
		} = state

		const url = short.toLowerCase()
		return {
			id,
			name,
			short,
			heroImage,
			url
		}
	})

	return {
		id,
		name,
		short,
		states
	}
}

export default destructureCountry
