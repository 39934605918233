<template>
	<div id="country-page">
		<HeroImage :title="country.name" />
		<div class="max-w-6xl pb-4 mx-auto">
			<div class="flex flex-wrap mt-2 md:mt-4">
				<div class="w-full py-4">
					<h2 class="text-4xl font-bold">
						Cannabis in {{ country.name }}
					</h2>
				</div>
			</div>

			<div
				v-if="country.states && country.states.length > 0"
				class="mt-2"
			>
				<div class="grid grid-cols-1 gap-4 px-3 mx-auto md:grid-cols-3 lg:grid-cols-4 md:px-0">
					<div
						v-for="(state, index) in country.states"
						:key="index"
					>
						<LocationCard
							:card-type="STATE"
							:location-name="state.name"
							:location-url="locationUrl(index)"
							:image-path="state.heroImage"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapState } from 'vuex'

import { STATE } from '@/constants/state/locationCardTypes.js'

export default {
	components: {
		HeroImage: () => import('@/components/multiUse/HeroImage.vue'),
		LocationCard: () => import('./components/LocationCard.vue')
	},
	data() {
		return {
			STATE
		}
	},
	computed: {
		...mapState({
			country: state => state.state.country
		})
	},
	methods: {
		locationUrl(index) {
			return this.country.states[index]?.name.toLowerCase().replace(' ', '-')
		}
	}
}
</script>
