<template>
	<router-link
		:to="routerLink"
		class="rounded-xl"
	>
		<div class="flex flex-col w-full h-full pb-2 bg-white shadow-md rounded-xl">
			<WwImage
				:src="imagePath"
				classes="rounded-t-xl"
				:alt="locationName"
				:fallback-image="DEFAULT_WW_GRAY_LOGO"
				:width="250"
				:height="100"
				:image-path="LOCATION_IMAGES"
			/>
			<div class="flex items-center justify-center h-full">
				<h3 class="pt-2 text-xl font-semibold">
					{{ locationName }}
				</h3>
			</div>
		</div>
	</router-link>
</template>

<script async>
import { LOCATION_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { CITY, STATE } from '@/constants/state/locationCardTypes.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		cardType: {
			type: String,
			required: true
		},
		locationName: {
			type: String,
			required: true
		},
		imagePath: {
			type: String,
			required: true
		},
		locationUrl: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			STATE,
			CITY,
			DEFAULT_WW_GRAY_LOGO,
			LOCATION_IMAGES
		}
	},
	computed: {
		isStateCard() {
			return this.cardType === STATE
		},
		isCityCard() {
			return this.cardType === CITY
		},
		routerLink() {
			return this.isStateCard ? { name: 'statePage', params: { state: this.locationUrl } } : { path: this.locationUrl }
		}
	}
}
</script>
