import destructureBlogArticles from '@/api/helpers/destructureBlogArticles.js'

const destructureState = function ({ state, heroImage }) {
	let blogArticles = []
	if (state.blogs) {
		blogArticles = destructureBlogArticles(state.blogs)
	}

	return {
		id: state.state_id,
		lat: state.state_lat,
		lon: state.state_lon,
		name: state.state_name,
		short: state.state_short,
		cities: state.cities,
		hasLaws: !!state.has_laws,
		country: {
			id: state.country_id
		},
		content: {
			faq: state.faqs,
			main: state.seo_text?.main_1,
			heroImage,
			intro: state.seo_text?.intro_text
		},
		features: {
			medical: state.has_medical,
			recreational: state.has_recreational
		},
		blogArticles
	}
}

export default destructureState
