<template>
	<div
		class="relative flex flex-wrap items-center w-full h-48 bg-center bg-cover lg:h-72"
		:style="{ 'background-image': `url(${imageSrc})`}"
	>
		<div class="absolute w-full h-full opacity-50 bg-gradient-to-b from-gray-500 to-blue-700" />
		<div class="z-10 flex justify-center w-full">
			<h1
				v-if="title != ''"
				class="py-4 text-3xl text-white"
			>
				{{ title }}
			</h1>
		</div>
	</div>
</template>

<script async>
import { mapMutations, mapState } from 'vuex'

import { LOCATION_IMAGES } from '@/constants/images/image-folder-paths.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default {
	props: {
		image: {
			type: String,
			default: 'default.jpg'
		},
		title: {
			type: String,
			default: ''
		},
		heroHeightLg: {
			type: Number,
			default: 300
		},
		heroHeightSm: {
			type: Number,
			default: 200
		},
		heroWidth: {
			type: Number,
			default: 1200
		}
	},
	computed: {
		...mapState([ 'mediaMatch', 'location' ]),
		heroHeight() {
			switch (this.mediaMatch) {
				case 'lg':
				case 'xl':
					return this.heroHeightLg
				default:
					return this.heroHeightSm
			}
		},
		imageAlt () {
			return `Where's Weed - ${this.location.name}`
		},
		imageSrc () {
			if (!this.imageSrcWebp) return
			return buildImageUrl({
				file: this.imageSrcWebp, imagePath: LOCATION_IMAGES, width: this.heroWidth, height: this.heroHeight
			})
		},
		imageSrcWebp() {
			if (typeof this.image === 'undefined' || this.image === false) return
			const ext = this.image.substring(this.image.lastIndexOf('.')).toLowerCase()
			if (ext === 'webp' || ext === 'svg') {
				return this.image
			} else {
				return `${this.image}.webp`
			}
		}
	},
	created() {
		if (this.imageSrc) {
			this.pushMetaLink({ rel: 'preload', href: this.imageSrc, as: 'image' })
		}
	},
	methods: {
		...mapMutations([ 'pushMetaLink' ])
	}
}
</script>
