const initialState = {
	id: 0,
	lat: 0,
	lon: 0,
	name: '',
	short: '',
	country: {
		id: 0
	},
	content: {
		faq: [],
		main: '',
		heroImage: '',
		intro: ''
	},
	cities: [],
	features: {
		medical: false,
		recreational: false
	},
	blogArticles: []
}

const mutations = {
	SET_STATE: function (state, data) {
		Object.assign(state, data)
	},
	SET_COUNTRY: function (state, data) {
		state.country = Object.assign({}, data)
	}
}

const actions = {
	SET_STATE: function (context, data) {
		context.commit('SET_STATE', data)
	},
	SET_COUNTRY: function (context, data) {
		context.commit('SET_COUNTRY', data)
	}
}
const state = {
	namespaced: true,
	state: () => {
		return initialState
	},
	mutations,
	actions
}

export default state
