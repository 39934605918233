<template>
	<DefaultLayout>
		<router-view id="state-shell" />
	</DefaultLayout>
</template>

<script async>
import { mapActions } from 'vuex'

import destructureCountry from '@/api/helpers/destructureCountry.js'
import destructureState from '@/api/helpers/destructureState.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import stateModule from '@/store/modules/stateModule.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		DefaultLayout
	},
	beforeRouteUpdate: function (to, from, next) {
		this.fetchData(to)
		next()
	},
	data () {
		return {
			isMounted: false
		}
	},
	beforeCreate: function () { // we need to register the modules for frontend.  If there was a previous state (from ssr) then preserve it
		const preserveState = this.$route.path === this.$store.state.ssrPageLoaded

		this.$store.registerModule('state', stateModule, { preserveState: preserveState })
	},
	serverPrefetch: function() {
		this.$store.commit('setSsrPageLoad', this.$route.fullPath)
		return this.fetchData(this.$route)
	},
	mounted: function () {
		this.isMounted = true
		return this.fetchData(this.$route)
	},
	methods: {
		fetchData: async function(to) {
			if (this.$store.state.ssrPageLoaded === to.fullPath && this.isMounted === true) {
				this.$store.commit('setSsrPageLoad', true)
				return
			}

			let endpoint = `country/${to.params.country}`

			if (to.params.state) {
				endpoint = `state/${to.params.state}`
			}

			const params = { }
			const response = await this.newGet({ endpoint, params })

			let storeData
			let storeAction = 'SET_STATE'
			if (response.data?.results.state && response.data?.results.state.state_id) {
				try {
					storeData = destructureState({
						state: response.data.results.state,
						heroImage: response.data.results.hero_image
					})
				} catch (e) {
					logError(e)
				}
			} else {
				try {
					storeData = destructureCountry(response.data.results)
					storeAction = 'SET_COUNTRY'
				} catch (e) {
					logError(e)
				}
			}
			try {
				this[storeAction](storeData)
			} catch (e) {
				logError(e)
			}
			return response
		},
		...mapActions('state', [ 'SET_STATE', 'SET_COUNTRY' ]),
		...mapActions([ 'newGet' ])
	}
}
</script>
